/* eslint-disable camelcase */
import React from 'react'
import styles from './index.module.scss'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { capitalize } from 'lodash'
import ContributionsCalculator, { PlanUnion, GroupPlanType, isMedical, moneyWeekly, moneyString, isAncillaryPlanUnion } from 'Utilities/Plans/ContributionCalculator'
import { typeOfPlan } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { AncillaryContributionMode } from '../ancillary'
import numeral from 'numeral'

interface OverallCartProps {
  plans: PlanUnion[]
  calc: ContributionsCalculator
  showWeeklyPayments: boolean
  mode: AncillaryContributionMode
}

const OverallCart: React.FC<OverallCartProps> = ({ plans, calc, showWeeklyPayments, mode, ...props }) => {
  const medical = plans.filter(p => typeOfPlan(p) === GroupPlanType.medical) as MedicalPlan[]
  const hasProsperPlan = medical.some((p) => p.hasProsper)
  const dental = plans.filter(p => typeOfPlan(p) === GroupPlanType.dental)
  const vision = plans.filter(p => typeOfPlan(p) === GroupPlanType.vision)
  const disability = plans.filter(p => typeOfPlan(p) === GroupPlanType.disability || typeOfPlan(p) === GroupPlanType.ltdER || typeOfPlan(p) === GroupPlanType.ltdEE)
  const hospital = plans.filter(p => typeOfPlan(p) === GroupPlanType.hospital)
  const cancer = plans.filter(p => typeOfPlan(p) === GroupPlanType.cancer)
  const std = plans.filter(p => typeOfPlan(p) === GroupPlanType.std || typeOfPlan(p) === GroupPlanType.stdER || typeOfPlan(p) === GroupPlanType.stdEE)
  const criticalIllness = plans.filter(p => typeOfPlan(p) === GroupPlanType.criticalIllness)
  const accident = plans.filter(p => typeOfPlan(p) === GroupPlanType.accident)
  const life = plans.filter(p => typeOfPlan(p) === GroupPlanType.life || typeOfPlan(p) === GroupPlanType.lifeER || typeOfPlan(p) === GroupPlanType.lifeEE)

  const carts = matrix(medical, dental, vision, life, disability, hospital, cancer, std, criticalIllness, accident)
  const sortedCarts = carts.sort((a, b) => {
    let returnValue = 0
    if (isMedical(a[0]) && isMedical(b[0])) {
      if (numeral(a[0].premium.employer).value() > numeral(b[0].premium.employer).value()) returnValue = 1
      if (numeral(a[0].premium.employer).value() < numeral(b[0].premium.employer).value()) returnValue = -1
    }
    return returnValue
  })

  return <section className={styles.oc_container} id={'total'}>
    <div className={styles.oc_inner}>
      <h2>Estimated Employer Total Costs</h2>
      <div className={styles.oc_cartsContainer}>
        {cart(sortedCarts[0], sortedCarts[sortedCarts.length - 1])}
      </div>
      {props.children}
    </div>
  </section>

  function cart(cheapestPlans: PlanUnion[], expensivePlans: PlanUnion[]) {
    /*
      Need another calc here since all ancillary contribution calculations are only done in `premiumsForAncillary()`
      and that uses all the current plans instead of the specific plan combination shown here.
    */
    const cheapestCartCalc = new ContributionsCalculator(cheapestPlans, calc.contributions, calc.splits, calc.members, calc.precision)
    const expensiveCartCalc = new ContributionsCalculator(expensivePlans, calc.contributions, calc.splits, calc.members, calc.precision)
    const cheapMedical = cheapestCartCalc.premiumsForMedical(undefined, false).er
    const expensiveMedical = expensiveCartCalc.premiumsForMedical(undefined, false).er
    const ancillary = cheapestCartCalc.premiumsForAncillary().er
    const lifeDisability = cheapestCartCalc.premiumsForDisability().er

    const cheapestTotal = cheapMedical + ancillary + lifeDisability
    const expensiveTotal = expensiveMedical + ancillary + lifeDisability
    const ancillaryCopy = getAncCopy(plans)
    return <div className={styles.oc_cart}>
      { cost('medical', cheapMedical, expensiveMedical) }
      { ancillaryCopy === 'ancillary' ? cost('ancillary', ancillary) : cost('ancillary and supplemental', ancillary) }
      { cost('life and Disability', lifeDisability)}
      { hasProsperPlan && cost('prosper', 0) }
      <hr/>
      { cost('total', cheapestTotal, expensiveTotal) }
    </div>

    function getAncCopy(_plans: any[]) {
      let copy = 'ancillary'
      if (mode === AncillaryContributionMode.perLine) {
        return copy
      } else {
        _plans.forEach(p => {
          if (isAncillaryPlanUnion(p)) {
            const type: string = p.plan.type
            if (type === 'hospital' || type === 'accident' || type === 'std' || type === 'cancer' || type === 'criticalillness') copy = 'ancillary and supplemental'
          }
        })
        return copy
      }
    }
  }

  function cost(type: 'medical' | 'ancillary' | 'prosper' | 'total' | 'ancillary and supplemental' | 'life and Disability', amount: number, amount2?: number) {
    let title: string
    switch (type) {
    case 'medical':
    case 'ancillary':
      title = `Estimated ${capitalize(type)} Plans`
      break
    case 'ancillary and supplemental':
      title = 'Estimated Ancillary and Supplemental Plans'
      break
    case 'prosper':
      title = 'Prosper Benefits+'
      break
    case 'total':
      title = 'Estimated Total Cost to Employer'
      break
    case 'life and Disability':
      title = 'Estimated Life and Disability'
      break
    }
    return <div className={styles.oc_line}>
      <div className={styles.oc_label}>{title}</div>

      { type === 'prosper' && amount === 0
        ? <div className={styles.oc_included}>Included</div>
        : <div className={styles.oc_cost}><span className={styles.dollar}>$</span>{showWeeklyPayments ? moneyWeekly(amount).replace('$', '') : moneyString(amount).replace('$', '')}
          <span>{showWeeklyPayments ? '/wk' : '/mo'}</span>
          { (!!amount2 && amount !== amount2) && <> <span className={styles.to}>to</span> <span className={styles.dollar}>$</span>{showWeeklyPayments ? moneyWeekly(amount2).replace('$', '') : moneyString(amount2).replace('$', '')}
            <span>{showWeeklyPayments ? '/wk' : '/mo'}</span></>}
        </div>
      }
    </div>
  }

  // https://stackoverflow.com/questions/15298912/javascript-generating-combinations-from-n-arrays-with-m-elements
  function matrix(...plans: PlanUnion[][]) {
    const filtered = plans.filter(p => p.length)
    const carts: PlanUnion[][] = []
    const max = filtered.length - 1

    function helper(arr:PlanUnion[], i: number) {
      for (let j = 0, l = filtered[i].length; j < l; j++) {
        const a = arr.slice(0) // clone arr
        a.push(filtered[i][j])
        if (i === max) {
          carts.push(a)
        } else {
          helper(a, i + 1)
        }
      }
    }
    helper([], 0)
    return carts
  }
}

export default OverallCart
